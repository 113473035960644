<template>
    <div>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    metaInfo() {
        return {
            title: '我的笔记-个人中心-文物出版社',
            meta: [
                {
                    name: 'keyWords',
                    content: '我的笔记-个人中心-文物出版社'
                },
                {
                    name: 'description',
                    content: '我的笔记-个人中心-文物出版社'
                }
            ]
        }
    },
}
</script>
<style scoped>
div {
    flex: 1;
    display: flex;
}
</style>